import React from "react";
import {Button, Layout, PageHeader} from 'antd';
import {ActivityForm} from "./ActivityForm";
import {Activity, LayoutType} from "./types";

type FixedHeightLayoutProps = {
    config: Activity;
    activeCollapses: string | string[];
    onConfigChange(newValue: Activity): void;
    onSwitchLayout(layout: LayoutType): void;
    onCollapsesChange(state: string | string[]): void;
}

export const FixedHeightLayout: React.FC<FixedHeightLayoutProps> = ({ config, activeCollapses, onConfigChange, onSwitchLayout, onCollapsesChange}) => {
    const {Sider, Content} = Layout;

    return (
        <Content>
            <PageHeader title={'Gradarius Activity Demo - Fixed Height Layout'}
                        extra={[<Button key={'switch'} type={'link'} onClick={() => onSwitchLayout('infinite')}>Switch to Infinite Height Layout</Button>]}
                        style={{backgroundColor: '#f0f2f5'}}>
            </PageHeader>
            <Layout style={{width: '100%', height: 'calc(100vh - 80px)'}}>
                <Sider theme="light" width={500} style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    <ActivityForm target={'_solver'} config={config} onConfigChange={onConfigChange} activeCollapses={activeCollapses} onCollapsesChange={onCollapsesChange}/>
                </Sider>
                <Content style={{ backgroundColor: 'white', width: '100%' }}>
                    <iframe allowFullScreen={true} name={'_solver'} title={'Solver'} height="100%" width="100%" style={{border: 'none', backgroundColor: 'white'}}/>
                </Content>
            </Layout>
        </Content>
    )
};
