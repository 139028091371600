import React, {useState} from "react";
import {Button, Layout, PageHeader, Row} from 'antd';
import {ActivityForm} from "./ActivityForm";
import IframeResizer from "iframe-resizer-react";
import {Activity, LayoutType} from "./types";

type InfiniteHeightLayoutProps = {
    config: Activity;
    activeCollapses: string | string[];
    onConfigChange(newValue: Activity): void;
    onSwitchLayout(layout: LayoutType): void;
    onCollapsesChange(state: string | string[]): void;
}

export const InfiniteHeightLayout: React.FC<InfiniteHeightLayoutProps> = ({config, activeCollapses, onConfigChange, onSwitchLayout, onCollapsesChange}) => {
    const {Sider, Content} = Layout;

    const [ready, setReady] = useState(false);

    return (
        <Content style={{width: '100%'}}>
            <PageHeader title={'Gradarius Activity Demo - Infinite Height Layout'}
                        extra={[<Button key={'switch'} type={'link'} onClick={() => onSwitchLayout('fixed')}>Switch to Fixed Height Layout</Button>]}
                        style={{backgroundColor: '#f0f2f5'}}>
            </PageHeader>
            <Row wrap={false}>
                <Sider theme="light" width={500}>
                    <ActivityForm target={'_solver'} config={config} onConfigChange={onConfigChange} activeCollapses={activeCollapses} onCollapsesChange={onCollapsesChange}/>
                </Sider>
                <Content style={{width: '100%', maxHeight: '100%', minHeight: '200px', overflowY: 'auto', backgroundColor: 'white', margin: '20px 0' }}>
                    {ready && <>Mathematics (from Ancient Greek μάθημα; máthēma: 'knowledge, study, learning') is an area of knowledge that includes such topics as numbers
                        (arithmetic and number theory),[2] formulas and related structures (algebra),[3] shapes and the spaces in which they are contained (geometry),[2]
                        and quantities and their changes (calculus and analysis).[4][5][6] Most mathematical activity involves the use of pure reason to discover or prove
                        the properties of abstract objects, which consist of either abstractions from nature or—in modern mathematics—entities that are stipulated with
                        certain properties, called axioms. A mathematical proof consists of a succession of applications of some deductive rules to already known results,
                        including previously proved theorems, axioms and (in case of abstraction from nature) some basic properties that are considered as true starting
                        points of the theory under consideration.</>}
                    <IframeResizer allowFullScreen={true} name={'_solver'} checkOrigin={false} heightCalculationMethod={'taggedElement'} title={'Solver'}
                                   style={{border: 'none', backgroundColor: 'white', width: '1px', minWidth: '100%'}} onLoad={() => setReady(true)}/>
                    {ready && <>Mathematics is used in science for modeling phenomena, which then allows predictions to be made from experimental laws. The independence of
                        mathematical truth from any experimentation implies that the accuracy of such predictions depends only on the adequacy of the model. Inaccurate
                        predictions, rather than being caused by incorrect mathematics, imply the need to change the mathematical model used. For example, the perihelion
                        precession of Mercury could only be explained after the emergence of Einstein's general relativity, which replaced Newton's law of gravitation as a
                        better mathematical model.</>}
                </Content>
            </Row>
        </Content>
    )
};
