import React, {useCallback, useEffect, useState} from "react";
import {Button, Checkbox, Collapse, Form, Input, Select, Switch} from 'antd';
import oauthSignature from 'oauth-signature';
import {useForm} from "antd/es/form/Form";
import {Activity, MathActionType, MathLevel} from "./types";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import TextArea from "antd/lib/input/TextArea";
import {getOAuthParams, postForm} from "../utils";

const {Option} = Select;

interface ActivityFormProps {
    target: string;
    config: Activity;
    activeCollapses: string | string[];

    onConfigChange(newValue: Activity): void;

    onCollapsesChange(state: string | string[]): void;
}

const overrideExample = '' +
    '{\n' +
    '  "CellsRowsFeedback::RowStatusFinalIncorrect": [],\n' +
    '  "CellsRowsFeedback::RowStatusFinalCorrect": ["Nice!", "U a awesome"]\n' +
    '}\n';

export const ActivityForm: React.FC<ActivityFormProps> = ({target, config, activeCollapses, onConfigChange, onCollapsesChange}) => {
    const [activityConfig, setActivityConfig] = useState<string>();
    const [message, setMessage] = useState<string>('');
    const [form] = useForm();

    useEffect(() => {
        const receiveMessage = (event: MessageEvent) => {
            console.log(event);
            setMessage(JSON.stringify(event.data, null, 2));
        }

        window.addEventListener("message", receiveMessage);
        return () => window.removeEventListener("message", receiveMessage);
    }, []);

    const valuesChange = useCallback((changedValues: any, newConfig: Activity) => {
        console.log('changedValues', changedValues)

        if (changedValues.newSolution) {
            form.setFieldsValue({checkWork: false});
            newConfig.checkWork = false;
        }

        if (changedValues.checkWork) {
            form.setFieldsValue({newSolution: false});
            newConfig.newSolution = false;
        }

        if (changedValues.activityType !== undefined) {
            const showGeneralHints = changedValues.activityType !== 'solo';
            form.setFieldsValue({showGeneralHints});
            newConfig.showInRowHints = showGeneralHints;
        }

        onConfigChange(newConfig);
    }, [form, onConfigChange]);

    const launch = useCallback((values: Activity) => {
        const action = values.ltiLaunchUrl;
        const method = 'POST';

        console.log(values);

        const activityConfig = {
            problem_id: values.problemId ? values.problemId : undefined,
            template_id: values.templateId ? values.templateId : undefined,
            activity_type: values.activityType,
            new_solution: values.newSolution ? values.newSolution : undefined,
            new_problem: values.newProblem ? values.newProblem : undefined,
            check_work: values.checkWork ? values.checkWork : undefined,
            hide_score: values.hideScore ? values.hideScore : undefined,
            suppress_submit_warning: values.suppressSubmitWarning || undefined,
            show_in_row_hints: values.showInRowHints === false ? false : undefined, // it's true by default
            show_in_row_feedback: values.showInRowFeedback === false ? false : undefined, // it's true by default
            show_general_hints: !!values.showGeneralHints,
            show_reveal_next_step_hint: values.showRevealNextStepHint === false ? false : undefined, // it's true by default
            show_reveal_next_step_feedback: values.showRevealNextStepFeedback === false ? false : undefined, // it's true by default
            math_message_overrides: values.mathMessageOverrides ? JSON.parse(values.mathMessageOverrides) : undefined,
            disabled_actions: values.overrideDisabledActions ? values.disabledActions : undefined,
            math_levels: values.overrideMathLevels ? values.mathLevels : undefined
        };

        setActivityConfig(JSON.stringify(activityConfig, null, 2));

        let params = {
            ...getOAuthParams(values.consumerKey),

            // LTI Required Parameters
            lti_message_type: 'basic-lti-launch-request',
            lti_version: 'LTI-1p0',
            user_id: values.userId,
            resource_link_id: values.resourceLinkId,

            // Custom Gradarius Parameters
            custom_grd_activity: JSON.stringify(activityConfig),
            custom_grd_a11y: values.a11y,
            custom_grd_high_contrast: values.highContrast,
        };

        if (values.resourceLinkTitle) {
            params = {...params, resource_link_title: values.resourceLinkTitle} as any;
        }

        if (values.contextId) {
            params = {...params, context_id: values.contextId} as any;
        }

        if (values.contextTitle) {
            params = {...params, context_title: values.contextTitle} as any;
        }

        const paramsWithSignature = {
            ...params,
            oauth_signature: oauthSignature.generate(method, action, params, values.sharedSecret, undefined, {encodeSignature: false})
        }

        postForm(paramsWithSignature, action, method, target);
        setMessage('');
    }, [target]);

    const fetchSubmissions = useCallback(() => {
        const targetUrl = new URL(config.ltiLaunchUrl);
        targetUrl.pathname = '/controller/gradarius/lti-activity/submissions'

        let params = {
            ...getOAuthParams(config.consumerKey),
            user_id: config.userId,
            resource_link_id: config.resourceLinkId
        }

        if (config.contextId) {
            params = {...params, context_id: config.contextId} as any;
        }

        const paramsWithSignature = {
            ...params,
            oauth_signature: oauthSignature.generate('POST', targetUrl.toString(), params, config.sharedSecret, undefined, {encodeSignature: false})
        }

        postForm(paramsWithSignature, targetUrl.toString(), 'POST', '_blank');
    }, [config])

    return (
        <>
            <Form labelCol={{span: 10}} wrapperCol={{span: 16}} style={{padding: '20px'}} form={form} initialValues={config} onFinish={launch} onValuesChange={valuesChange}>
                <Form.Item>
                    <h2>LTI Activity Request</h2>
                </Form.Item>
                <Form.Item
                    label="Consumer key"
                    name="consumerKey"
                    rules={[{required: true, message: 'Please input consumer key'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Shared secret"
                    name="sharedSecret"
                    rules={[{required: true, message: 'Please input shared secret'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="LTI launch URL"
                    name="ltiLaunchUrl"
                    rules={[{required: true, message: 'Please input LTI launch URL'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="User ID"
                    name="userId"
                    rules={[{required: true, message: 'Please input user ID'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 3}} style={{marginTop: -15}}>
                    Uniquely identifies the user. Change this parameter if you want to solve a problem as a new user.
                </Form.Item>
                <Form.Item style={{marginBottom: 0}} label={'A11y and high contrast'}>
                    <Form.Item style={{display: 'inline-block', textAlign: 'right'}} name="a11y"
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                    &nbsp;&nbsp;
                    <Form.Item style={{display: 'inline-block', textAlign: 'right'}} name="highContrast"
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="Resource Link ID"
                    name="resourceLinkId"
                    rules={[{required: true, message: 'Please input resource link ID'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Resource Link Title"
                    name="resourceLinkTitle">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Context ID"
                    name="contextId">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Context Title"
                    name="contextTitle">
                    <Input/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 3}} style={{marginTop: -15}}>
                    Once activity is created it is bound to the resource_link_id and parameters problem_id, template_id
                    and activity_type will be ignored. You must change
                    resource_link_id
                    in order to open a new activity.
                </Form.Item>
                <Form.Item label="Problem ID" name="problemId">
                    <Input/>
                </Form.Item>
                <Form.Item label="Template ID" name="templateId">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Activity type"
                    name="activityType"
                    rules={[{required: true, message: 'Please select activity type'}]}>
                    <Select>
                        <Option value="tutor">Tutor</Option>
                        <Option value="study">Study</Option>
                        <Option value="solo">Solo</Option>
                    </Select>
                </Form.Item>
                <Collapse style={{marginBottom: "16px"}} activeKey={activeCollapses} onChange={onCollapsesChange}>
                    <CollapsePanel key={"extra"} header={"Extra settings"}>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Check work" name="checkWork"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="New solution" name="newSolution"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="New problem" name="newProblem"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Hide score" name="hideScore"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Suppress submit warning" name="suppressSubmitWarning"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Show in-row hints"
                                   name="showInRowHints" valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Show in-row feedback"
                                   name="showInRowFeedback" valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Show general hints"
                                   name="showGeneralHints" valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Show hint message with next step"
                                   name="showRevealNextStepHint"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>
                        <Form.Item labelCol={{span: 20}} wrapperCol={{span: 6}} label="Show feedback with next step"
                                   name="showRevealNextStepFeedback"
                                   valuePropName="checked">
                            <Switch/>
                        </Form.Item>

                        <Form.Item label="Disabled actions" name="disabledActions" style={{ marginBottom: 0}}>
                            <Select mode={"multiple"} disabled={!config.overrideDisabledActions}>
                                {Object.keys(MathActionType).map(key => <Option key={key} value={key}>{key}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Override" name="overrideDisabledActions" valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>

                        <Form.Item label="Math level" name="mathLevels" style={{ marginBottom: 0}}>
                            <Select mode={"multiple"} defaultValue={MathLevel.prealgebra} disabled={!config.overrideMathLevels}>
                                {Object.keys(MathLevel).map(key => <Option key={key} value={key}>{key}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Override" name="overrideMathLevels" valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>
                    </CollapsePanel>
                    <CollapsePanel key={"overrides"} header={"Math message overrides JSON"}>
                        <Form.Item wrapperCol={{}} name="mathMessageOverrides">
                            <TextArea rows={10} placeholder={"JSON"}/>
                        </Form.Item>
                        <Form.Item wrapperCol={{}}>
                            <div>Example:</div>

                            <pre style={{fontSize: '10px', margin: '10px 0 0 0'}}>
                                {overrideExample}
                            </pre>
                        </Form.Item>
                    </CollapsePanel>
                </Collapse>

                <div style={{display: "flex", justifyContent: "flex-end", gap: "1em", padding: "10px 0"}}>
                    <Button type="primary" htmlType="submit">Launch</Button>
                    <Button type="ghost" onClick={fetchSubmissions}>View submissions</Button>
                </div>

                {activityConfig && (<Form.Item>
                    <h2>Activity Config</h2>
                    <div style={{whiteSpace: 'pre'}}>{activityConfig}</div>
                </Form.Item>)}
            </Form>
            <div style={{padding: '0 20px'}}>
                <h2>Post Message</h2>
                <p style={{whiteSpace: 'pre'}}>{message}</p>
            </div>
        </>
    )
}
