export const getOAuthParams = (consumerKey: string) => {
    const timestamp = Math.round(Date.now() / 1000);

    return {
        oauth_consumer_key: consumerKey,
        oauth_nonce: btoa(timestamp + ''),
        oauth_signature_method: 'HMAC-SHA1',
        oauth_timestamp: timestamp,
        oauth_version: '1.0',
    }
}

export const postForm = (paramsWithSignature: { [key: string]: any }, action: string, method: string, target: string) => {
    const form = window.document.createElement('form');
    form.style.cssText = 'display: none;';
    form.action = action;
    form.method = method;
    form.target = target;

    Object.entries(paramsWithSignature).forEach(([name, value]) => {
        const input = window.document.createElement('input');
        input.name = name;
        input.value = value as string;
        input.type = 'hidden';
        form.appendChild(input);
    });

    window.document.body.appendChild(form);
    form.submit();
}
