import React, {useState} from 'react';
import 'antd/dist/antd.css';
import './main.css';
import {InfiniteHeightLayout} from "./components/InfiniteHeightLayout";
import {FixedHeightLayout} from "./components/FixedHeightLayout";
import {Activity, LayoutType, MathLevel} from "./components/types";

const initialConfig: Activity = {
    consumerKey: '',
    sharedSecret: '',
    ltiLaunchUrl: 'https://macs-dev.gradarius.com/controller/activity/lti',
    userId: 'harry potter',
    resourceLinkId: 'the philosopher\'s stone',
    resourceLinkTitle: 'the philosopher\'s stone',
    problemId: "7fb0fb86-6699-4929-8560-57f2c6949dba", //558897d3-1dad-4dc5-aa0e-8fbf2ff596e2
    activityType: "tutor",
    newSolution: false,
    newProblem: false,
    checkWork: false,
    a11y: false,
    highContrast: false,
    hideScore: false,
    suppressSubmitWarning: false,
    showInRowHints: true,
    showInRowFeedback: true,
    showGeneralHints: true,
    showRevealNextStepHint: true,
    showRevealNextStepFeedback: true,
    disabledActions: [],
    mathLevels: [MathLevel.prealgebra],
    overrideDisabledActions: false,
    overrideMathLevels: false,
};

function App() {
    const [layout, setLayout] = useState<LayoutType>('fixed');
    const [config, setConfig] = useState(() => initialConfig);
    const [activeCollapses, setActiveCollapses] = useState<string | string[]>("");
    return layout === 'fixed' ?
        <FixedHeightLayout config={config} onConfigChange={setConfig} onSwitchLayout={setLayout}
                           activeCollapses={activeCollapses} onCollapsesChange={setActiveCollapses}/> :
        <InfiniteHeightLayout config={config} onConfigChange={setConfig} onSwitchLayout={setLayout}
                              activeCollapses={activeCollapses} onCollapsesChange={setActiveCollapses}/>;
}

export default App;
