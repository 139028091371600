export type LtiAuth = {
    consumerKey: string;
    sharedSecret: string;
}

export type LtiRequest = {
    ltiLaunchUrl: string;
    userId: string;
    resourceLinkId: string;
    resourceLinkTitle?: string;
    contextId?: string;
    contextTitle?: string;
    problemId?: string;
    templateId?: string;
    activityType: 'tutor' | 'solo' | 'study';
    newSolution: boolean;
    newProblem: boolean;
    checkWork: boolean;
    a11y: boolean;
    highContrast: boolean;
    hideScore: boolean;
    suppressSubmitWarning: boolean;
    showInRowHints?: boolean;
    showInRowFeedback?: boolean;
    showGeneralHints?: boolean;
    showRevealNextStepHint?: boolean;
    showRevealNextStepFeedback?: boolean;
    mathMessageOverrides?: string;
    disabledActions?: MathActionType[]
    mathLevels?: MathLevel[]
}

type AdditionalParams = {
    overrideDisabledActions: boolean
    overrideMathLevels: boolean
}

export type LayoutType = 'fixed' | 'infinite';

export type Activity = LtiAuth & LtiRequest & AdditionalParams;

export enum MathActionType {
    CALCULUS = 'CALCULUS',
    MAGIC = 'MAGIC',
    EQSOLVE = 'EQSOLVE',
    ALGEBRA = 'ALGEBRA',
    OTHER = 'OTHER',
    BASICVECTORS = 'BASICVECTORS',
    GRAPH = 'GRAPH',
    REWRITING = 'REWRITING',
    ORGANIZER = 'ORGANIZER',
    SCIENCECALC = 'SCIENCECALC',
    GRAPHINGCALC = 'GRAPHINGCALC',
    CAS = 'CAS',
}

export enum MathLevel {
    prealgebra = 'prealgebra',
    algebra = 'algebra',
    trigon = 'trigon',
    precalc = 'precalc',
    calc1 = 'calc1',
    calc2 = 'calc2',
    calc3 = 'calc3',
}